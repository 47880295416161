<template>
  <tp-modal name="modal-serials-update-serial" max-width="768px">
    <v-card flat>
      <v-card-title>
        <div class="font-weight-bold">Cập nhật serial</div>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-container class="grey lighten-3 pa-5" fluid>
        <!-- Start: Serial -->
        <v-card flat>
          <v-card-title class="align-center px-5">
            <div class="mr-3">Serial #{{ serial.serial_number }}</div>
            <v-text-field
              v-if="isUpdateSerial"
              class="text-body-1 rounded-lg mr-3"
              flat
              dense
              hide-details
              single-line
              placeholder="Nhập serial mới, nhấn enter để xác nhận"
              solo
              outlined
              @keydown.enter.prevent="updateSerialNumber"
            ></v-text-field>
            <div
              class="label-change text-body-1"
              @click="isUpdateSerial = !isUpdateSerial"
            >
              {{ isUpdateSerial ? "Hủy bỏ" : "Thay đổi" }}
            </div>
          </v-card-title>

          <div class="px-5 pb-5">
            <div class="mb-1">
              <span class="font-weight-bold">Phiếu nhập:</span>
              {{ serial.hoa_don_nhap }}
            </div>
            <div class="mb-1">
              <span class="font-weight-bold">Giá nhập:</span>
              {{ serial.created_price | formatCurrency }}
            </div>
            <div class="">
              <span class="font-weight-bold">Hạn bảo hành:</span>
              {{ serial.due_time }}
            </div>
          </div>
        </v-card>
        <!-- End: Serial -->
        <!-- Start: SKU -->
        <v-card class="mt-5" flat>
          <v-container class="px-5 pb-5" fluid>
            <v-row>
              <v-col class="" cols="6">
                <div class="d_flex align-center pb-2 px-0">
                  <div class="text-h6 mr-3">SKU</div>
                  <div
                    class="label-change text-body-1"
                    @click="isUpdateSKU = !isUpdateSKU"
                  >
                    {{ isUpdateSKU ? "Hủy bỏ" : "Thay đổi" }}
                  </div>
                </div>
              </v-col>
              <v-col class="" cols="6">
                <div class="d_flex align-center px-0" v-show="isUpdateSKU">
                  <v-autocomplete
                    class="text-body-1 rounded-lg mr-3"
                    clearable
                    dense
                    flat
                    hide-details
                    :items="searchProductOptions"
                    item-text="SKU"
                    item-value="id"
                    no-data-text="Không có dữ liệu"
                    no-filter
                    placeholder="Tìm SKU mới, nhấn enter để xác nhận"
                    prepend-inner-icon="mdi-magnify"
                    return-object
                    solo
                    outlined
                    :search-input.sync="productOptionSearchKey"
                    @change="getProductOptions($event)"
                    @keydown.enter.prevent="updateSKU($event)"
                  ></v-autocomplete>
                  <v-btn
                    class="cyan lighten-5 rounded-lg px-0"
                    height="40px"
                    width="40px"
                    depressed
                    color="primary"
                    icon
                    @click="openModalAddOption"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="6">
                <v-card class="tp-border-thin pa-4" flat>
                  <div class="text-body-1 mb-1">
                    <span class="font-weight-bold">Mã sản phẩm:</span>
                    {{ serial.option.product_code }}
                  </div>
                  <div class="text-body-1 mb-1">
                    <span class="font-weight-bold">Tên sản phẩm:</span>
                    {{ serial.option.product_name }}
                  </div>
                  <div class="text-body-1  mb-1">
                    <span class="font-weight-bold">SKU:</span>
                    {{ serial.option.option_sku }} -
                    <span v-html="serial.option.option_name"></span>
                  </div>
                  <div class="text-body-1  mb-1">
                    <span class="font-weight-bold">Giá bán:</span>
                    {{ serial.option.price | formatCurrency }}
                  </div>
                </v-card>
              </v-col>
              <!-- Start: New SKU info-->
              <v-col class="py-0" cols="6">
                <v-card
                  class="tp-border-thin pa-4"
                  v-if="isUpdateSKU && newOption"
                  flat
                >
                  <div class="text-body-1 mb-1">
                    <span class="font-weight-bold">Mã sản phẩm:</span>
                    {{ newOption.product_code }}
                  </div>
                  <div class="text-body-1 mb-1">
                    <span class="font-weight-bold">Tên sản phẩm:</span>
                    {{ newOption.product_name }}
                  </div>
                  <div class="text-body-1  mb-1">
                    <span class="font-weight-bold">SKU:</span>
                    {{ newOption.SKU }} -
                    <span v-html="newOption.name"></span>
                  </div>
                  <div class="text-body-1  mb-1">
                    <span class="font-weight-bold">Giá bán:</span>
                    {{ newOption.price | formatCurrency }}
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <!-- End: SKU -->
      </v-container>
    </v-card>
    <!-- Start: Modal update serial -->
    <modal-product-option></modal-product-option>
    <!-- End: Modal update serial -->
  </tp-modal>
</template>

<script>
import ModalProductOption from "@/core/components/modals/ModalProduct";
export default {
  components: {
    ModalProductOption
  },
  data() {
    return {
      isUpdateSerial: false,
      isUpdateSKU: false,
      newOption: {},
      productOptionSearchKey: null
    };
  },

  filters: {
    formatCurrency(value) {
      // Create our number formatter.
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  watch: {
    productOptionSearchKey(val) {
      this.$store.dispatch("SERIAL/getSearchProductOptions", { search: val });
    }
  },
  methods: {
    openModalAddOption() {
      this.$modal.show({
        name: "modal-product"
      });
    },
    closeModal() {
      this.$modal.hide({ name: "modal-serials-update-serial" });
    },
    getProductOptions(val) {
      this.newOption = val;
    },
    async updateSerialNumber(e) {
      await this.$store.dispatch("SERIAL/updateSerialNumber", {
        serial_id: this.serial.id,
        new_serial_number: e.target.value
      });
      this.isUpdateSerial = false;
      if (this.serialRequestStatus.value === "success-update-serial-number") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã thay đổi serial"
          }
        });
      }
    },
    async updateSKU() {
      await this.$store.dispatch("SERIAL/updateSKU", {
        serial_id: this.serial.id,
        new_option_id: this.newOption.id
      });
      this.isUpdateSKU = false;
      this.newOption = {};
      this.productOptionSearchKey = null;
      if (this.serialRequestStatus.value === "success-update-sku") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã thay đổi SKU"
          }
        });
      }
    }
  },
  computed: {
    serial() {
      return this.$store.getters["SERIAL/forUpdateSerial"];
    },
    serialRequestStatus() {
      return this.$store.getters["SERIAL/statusRequest"];
    },
    searchProductOptions() {
      return this.$store.getters["SERIAL/searchProductOptions"];
    }
  }
};
</script>

<style lang="scss" scoped>
.label-change {
  cursor: pointer;
  text-decoration: underline;
}
</style>
