<template>
  <v-container class="pt-0 pb-5 px-5" fluid>
    <div class="d_flex align-center justify-space-between">
      <div class="text-h6 font-weight-bold">Lịch sử ({{ hisCount }})</div>
      <v-btn
        class="rounded-lg grey--text text--darken-3"
        color="grey lighten-3"
        dense
        depressed
        @click.prevent="sort = !sort"
      >
        {{ sort ? "Cũ nhất" : "Mới nhất" }}
        <v-icon right>
          {{
            sort
              ? "mdi-sort-calendar-descending"
              : "mdi-sort-calendar-ascending"
          }}
        </v-icon>
      </v-btn>
    </div>
    <v-data-table
      class="datatable py-2"
      calculate-widths
      disable-pagination
      no-data-text="Không có serial nào"
      no-results-text="Không tìm thấy kết quả phù hợp"
      :headers="headers"
      hide-default-footer
      hide-default-header
      :loading="false"
      loading-text="Đang tải dữ liệu"
      :items="his"
      item-key="id"
    >
      <template v-slot:[`item.status`]="{ item }">
        <v-chip class="font-weight-medium px-2" color="green" outlined small>
          {{ item.status }}
        </v-chip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  props: ["histories"],
  data() {
    return {
      headers: [
        {
          text: "Ngày",
          align: "start",
          sortable: false,
          value: "desc"
        },
        {
          text: "Mã phiếu nhập",
          align: "start",
          sortable: false,
          value: "status"
        },
        {
          text: "Giá nhập",
          align: "center",
          sortable: false,
          value: "created_at"
        }
      ],
      sort: false
    };
  },
  computed: {
    his() {
      let result = this.histories.map(e => {
        return {
          desc: `${e.type} bởi ${e.by.name} với mã phiếu ${e.code}`,
          status: e.status === 1 ? "Thành công" : "Thất bại",
          created_at: e.created_at,
          atTime: e.atTime
        };
      });
      if (this.sort) {
        result = [...result].sort(function(a, b) {
          return new Date(a.atTime) - new Date(b.atTime);
        });
      }
      return result;
    },
    hisCount() {
      return this.histories.length;
    }
  }
};
</script>

<style lang="scss" scoped>
.card-info {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.075) !important;
  height: 100%;
}
</style>
