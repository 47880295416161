<template>
  <v-container class="pt-0 pb-5" fluid>
    <v-row>
      <!-- Start: General info -->
      <v-col class="px-5 py-0" cols="4">
        <v-card class="card-info rounded-lg" flat>
          <v-card-title class="justify-center px-5 pt-5">
            <v-icon color="orange lighten-3">mdi-alert-circle</v-icon>
            <div class="font-weight-medium ml-2">Thông tin sản phẩm</div>
          </v-card-title>
          <div class="px-5 pb-5">
            <div class="mb-1">
              <span class="font-weight-bold">Tên sản phẩm:</span>
              {{ productInfo.product_name }}
            </div>
            <div class="mb-1">
              <span class="font-weight-bold">SKU:</span>
              {{ productInfo.option_sku }} -
              <span class="tag-p__mb-0" v-html="productInfo.option_name"></span>
            </div>
            <div v-if="isViewedCreatedPrice" class="mb-1">
              <span class="font-weight-bold">Giá nhập:</span>
              {{ productInfo.created_price | formatCurrency }}
            </div>
            <div class="mb-1">
              <span class="font-weight-bold">Giá bán:</span>
              {{ productInfo.sold_price | formatCurrency }}
            </div>
            <div>
              <span class="font-weight-bold">Chi nhánh:</span>
              {{ productInfo.branch_name }}
            </div>
          </div>
        </v-card>
      </v-col>
      <!-- Start: Guarantee info -->
      <v-col class="px-5 py-0" cols="4">
        <v-card class="card-info rounded-lg" flat>
          <v-card-title class="justify-center px-5 pt-5">
            <v-icon color="orange lighten-3">mdi-hammer-wrench</v-icon>
            <div class="font-weight-medium ml-2">Thông tin bảo hành</div>
          </v-card-title>
          <div class="px-5 pb-5">
            <div class="mb-1">
              <span class="font-weight-bold">Gói bảo hành:</span>
              {{ guaranteeInfo.name }}
            </div>
            <div class="mb-1">
              <span class="font-weight-bold">Ngày kích hoạt:</span>
              {{ guaranteeInfo.trigger_time }}
            </div>
            <div class="">
              <span class="font-weight-bold">Hạn bảo hành:</span>
              {{ guaranteeInfo.due_time }}
            </div>
          </div>
        </v-card>
      </v-col>
      <!-- End: Guarantee info -->
      <!-- Start: Guarantee info -->
      <v-col class="px-5 py-0" cols="4">
        <v-card class="card-info rounded-lg" flat>
          <v-card-title class="justify-center px-5 pt-5">
            <v-icon color="orange lighten-3">mdi-account</v-icon>
            <div class="font-weight-medium ml-2">Thông tin khách hàng</div>
          </v-card-title>
          <div class="px-5 pb-5">
            <template v-if="boughtBy">
              <div class="mb-1">
                <span class="font-weight-bold">Tên khách hàng:</span>
                {{ boughtBy.name }}
              </div>
              <div class="mb-1">
                <span class="font-weight-bold">SĐT:</span>
                {{ boughtBy.phone | VMask("### ### ####") }}
              </div>
              <div class="mb-1">
                <span class="font-weight-bold">Email:</span>
                {{ boughtBy.email }}
              </div>
              <div class="">
                <span class="font-weight-bold">Hóa đơn bán:</span>
                {{ boughtBy.hdb_code }}
              </div>
            </template>
            <template v-else>
              <div class="white text-center rounded py-8">
                Serial chưa được bán.
              </div>
            </template>
          </div>
        </v-card>
      </v-col>
      <!-- End: Guarantee info -->
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["productInfo", "guaranteeInfo", "boughtBy"],
  filters: {
    formatCurrency(value) {
      // Create our number formatter.
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-info {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.075) !important;
  height: 100%;
}
</style>
